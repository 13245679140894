// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

//open iconics
@import "~open-iconic/font/css/open-iconic-bootstrap.scss";
@import "~open-iconic/font/css/open-iconic.scss";

.oi-circle-x {
  color:#DA3A35;
}
.oi-circle-check {
  color: #1e7e57;
}
.oi-person {
  color: #8b3c1e;
}